import * as Climatiq from '@carbonfact/shared/src/types/climatiq';
import type {
  MetricsCubeQueryData,
  MetricsCubeQueryOptions,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import { capitalizeWords } from '@carbonfact/shared/src/utils';
import { formatCount, formatPercent } from 'app/lib/formatNumber';
import { upperFirst } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo } from 'react';

interface MetricsCubeFormattingUtils {
  getMetricValue: (d: MetricsCubeQueryData) => number;
  formatMetricValue: (v: number) => string;
  metricSuffix: string;
  formatMetricsCubeString: (kayaString?: string | null) => string;
  getMetricOrDimensionDescription: (
    kayaString?: string | null,
  ) => string | undefined;
}

// A collection of printing and formatting utilities to display metrics-cube data
export default function useMetricsCubeFormattingUtils(
  options: MetricsCubeQueryOptions,
): MetricsCubeFormattingUtils {
  const t = useTranslations();

  const findTranslationOrNull = useCallback(
    (key: string): string | null => {
      const translation = t(key);
      return translation === key ? null : translation;
    },
    [t],
  );

  const [getMetricValue, formatMetricValue, metricSuffix] = useMemo<
    [
      MetricsCubeFormattingUtils['getMetricValue'],
      MetricsCubeFormattingUtils['formatMetricValue'],
      MetricsCubeFormattingUtils['metricSuffix'],
    ]
  >(() => {
    switch (options.metric) {
      case 'FOOTPRINT':
        return [
          (d) => d.metricValue / 1000, // convert kgCO2e to tCO2e
          (v) => formatCount(v),
          'tCO2e',
        ];
      case 'UNITS':
      case 'UNITS_MEASURED':
        return [(d) => d.metricValue, (v) => formatCount(v), 'units'];
      case 'MASS':
      case 'MASS_WITH_LOSS':
      case 'MASS_MEASURED':
      case 'MASS_WITH_LOSS_MEASURED':
        return [
          (d) => d.metricValue / 1000, // convert grams to kg
          (v) => formatCount(v),
          'kg',
        ];
      case 'UNCERTAINTY':
        return [
          (d) => d.metricValue / 1000, // convert kgCO2e to tCO2e
          (v) => formatCount(v),
          'tCO2e',
        ];
      case 'composite_MASS_PER_UNIT':
        return [(d) => d.metricValue, (v) => formatCount(v), 'kg/unit'];
      case 'composite_FOOTPRINT_PER_UNIT':
        return [(d) => d.metricValue, (v) => formatCount(v), 'kgCO2e/unit'];
      case 'composite_FOOTPRINT_PER_MASS':
        return [
          (d) => d.metricValue * 1000, // grams to kg
          (v) => formatCount(v),
          'kgCO2e/kg',
        ];
      case 'composite_UNITS_SHARE':
      case 'composite_UNITS_MEASURED_SHARE':
      case 'composite_FOOTPRINT_SHARE':
      case 'composite_MASS_SHARE':
      case 'composite_MASS_MEASURED_SHARE':
      case 'composite_MASS_WITH_LOSS_SHARE':
      case 'composite_MASS_WITH_LOSS_MEASURED_SHARE':
        return [(d) => d.metricValue, (v) => formatPercent(v), '%'];
      default:
        return [(d) => d.metricValue, (v) => formatCount(v), 'metric'];
    }
  }, [options.metric]);

  const formatMetricsCubeString = useCallback<
    MetricsCubeFormattingUtils['formatMetricsCubeString']
  >(
    (kayaString) => {
      if (!kayaString) return 'unknown';

      const normalizedKayaString = kayaString
        .toLowerCase()
        .trim()
        .replaceAll('composite_', ''); // marker for composite metrics

      // Fallback through different possibilities to convert the string to a meaningful
      // and localized UI copy

      // Metrics cube dimension name and descriptions
      const explorerDimensionName = findTranslationOrNull(
        `explorerDimensions.${normalizedKayaString}.name`,
      );
      if (explorerDimensionName) return upperFirst(explorerDimensionName);

      // Product materials
      const productMaterialName = findTranslationOrNull(
        `materials.${normalizedKayaString.toUpperCase()}`,
      );
      if (productMaterialName) return upperFirst(productMaterialName);

      // Climatiq activity types, used for Expenses
      const climatiqActivityKey = Object.entries(Climatiq.ActivityId).find(
        ([_, value]) => value === normalizedKayaString,
      )?.[0];
      if (climatiqActivityKey) {
        const climatiqActivityName = findTranslationOrNull(
          `Expenses.expense.activityTypeValues.${climatiqActivityKey}`,
        );
        if (climatiqActivityName) return upperFirst(climatiqActivityName);
      }

      // Transport modes (road, air...)
      const transportModeName = findTranslationOrNull(
        `Transport.transport.transportModeValues.${normalizedKayaString}`,
      );
      if (transportModeName) return upperFirst(transportModeName);

      // If no translation was found, fall back to a cleaned up version of the Kaya string
      return capitalizeWords(
        normalizedKayaString
          .replaceAll('_', ' ')
          .replaceAll('#', ' ')
          .replaceAll('slug', ''),
      );
    },
    [findTranslationOrNull],
  );

  const getMetricOrDimensionDescription = useCallback<
    MetricsCubeFormattingUtils['getMetricOrDimensionDescription']
  >(
    (kayaString) => {
      if (!kayaString) return undefined;

      const normalizedKayaString = kayaString.toLowerCase().trim();

      const translation = t(
        `explorerDimensions.${normalizedKayaString}.description`,
      );
      const wasTranslated =
        translation !==
        `explorerDimensions.${normalizedKayaString}.description`;

      if (!wasTranslated) return '';

      return translation;
    },
    [t],
  );

  return {
    getMetricValue,
    formatMetricValue,
    metricSuffix,
    formatMetricsCubeString,
    getMetricOrDimensionDescription,
  };
}
